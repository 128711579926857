import React, {useState, useEffect} from 'react';
import AdvCardItem from "./AdvCardItem";
import PageHeader from "./PageHeader";
import CustomAdsNew from "./CustomAdsNew";
import axios from "axios";


function OffersListSectionBrokers({cardType = Math.random()}) {
    const [showPreloader, setShowPreloader] = useState(true)
    const [offersList, setOffersList] = useState([])

    useEffect(() => {
        axios.get('https://api.ldtrk.ru/api/content/v1/get_content/offer?token=7d0bac20-4bd5-42af-a376-343411facd7b&rnd=true').then(res => {
            setOffersList(res.data.content)
            setShowPreloader(false)
        })
    }, [])


    return (
        <>
            {showPreloader ?
                <section className='position-relative overflow-hidden pt-5 py-5 pt-lg-3 preloader__section'>
                    <img src={require('../assets/images/Rolling-1s-128px.gif')} width={48}/>
                    <p className='mt-3'>Подбираем персональные предложения...</p>
                </section>
                : <>
                    <PageHeader currentStep={4}/>

                    {/*<pre>{JSON.stringify(offersList, null, 2)}</pre>*/}

                    <section className='position-relative overflow-hidden pt-5 pt-lg-3 form-section__wrapper'>
                        <div className='container'>
                            <div className='row mt-2 mb-5'>
                                <div className="col-lg-8 mx-auto text-center">
                                    <h2 className="fs-1">Персональные предложения 🔥</h2>
                                    <p className="mb-0">
                                        Для гарантированного получения займа, нужно оставить заявку минимум в 3 МФО
                                    </p>
                                </div>

                            </div>

                            <div className='row'>
                                {offersList.map((offer, position) => {
                                    return (
                                        <>
                                            <div className='col-md-4 col-sm-12 mb-3 mb-md-0' key={offer.id}>
                                                <AdvCardItem
                                                    imgAlt={offer.offerName}
                                                    imgSource={offer.offerImageUrl}
                                                    imgTitle={offer.offerName}
                                                    badgeText={'выгодно'}
                                                    badgeClass={''}
                                                    offerLink={offer.offerUrl}
                                                    loanAmount={`до ${offer.amountTo.toLocaleString()} руб.`}
                                                    cardText={`от ${offer.dailyRate}% в день`}
                                                    offerPosition={position + 1}
                                                />
                                            </div>
                                            {(position + 1) % 3 === 0 && <CustomAdsNew/>}
                                        </>
                                    )
                                })}
                            </div>

                        </div>
                    </section>

                </>
            }
        </>
    )
}

export default OffersListSectionBrokers