import React, { useEffect } from "react";

const CustomAdNew = () => {
    useEffect(() => {
        // Проверяем, есть ли уже скрипт на странице, чтобы не загружать его несколько раз
        const scriptId = "adsfin-script";
        if (!document.getElementById(scriptId)) {
            const script = document.createElement("script");
            script.id = scriptId;
            script.type = "text/javascript";
            script.async = true;
            script.src = "https://front.adsfin.net/place/163348643758908111/";
            document.body.appendChild(script);
        }
    }, []);

    useEffect(() => {
        // Проверяем, есть ли уже скрипт на странице, чтобы не загружать его несколько раз
        const scriptId = "adsfin-script";
        if (!document.getElementById(scriptId)) {
            const script = document.createElement("script");
            script.id = scriptId;
            script.type = "text/javascript";
            script.async = true;
            script.src = "https://front.adsfin.net/place/163348700126941112/";
            document.body.appendChild(script);
        }
    }, []);

    return (
        <div className="my-4 d-flex justify-content-center">
            <ins id="place_163348643758908111"></ins>

            <ins id="place_163348700126941112"></ins>


        </div>
    );
};

export default CustomAdNew;