import React, {useState, useEffect} from 'react';
import AdvCardItem from "./AdvCardItem";
import RegularCardItem from "./RegularCardItem";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import APIConfig from "../utils/api";
import {uuidv4} from "../utils";


function handleLinkParams() {
    const params = new URLSearchParams(window.location.search)
    const paramsDict = {}
    for (const param of params) {
        paramsDict[param[0]] = param[1]
    }
    if (Object.keys(paramsDict).length) {
        localStorage.setItem('query_params', JSON.stringify(paramsDict))
    }
}


function OffersListSectionRandomBackend({offerType = 'arbitrage', webmasterId = 0, limit = 1000, cardType = Math.random()}) {
    const [showPreloader, setShowPreloader] = useState(true)
    const [offersList, setOffersList] = useState([])

    const handleOfferImpressions = (randomOffers, offers_list_id) => {
        const impressionsPayload = {
            'offers_list_id': offers_list_id,
            'user_id': localStorage.getItem('user_id'),
            'offers_list': [],
            'card_type': cardType < 0.5 ? 'adv' : 'reg'
        }
        impressionsPayload['offers_list'] = randomOffers.slice(0, limit).map((item, index) => {
            return {'offer_id': item.id, 'offer_position': index + 1}
        })
        return impressionsPayload
    }

    const getUserOffers = (offers_list_id) => {
        let queryString = window.location.search
        const params = new URLSearchParams(queryString);
        const amount = parseInt(params.get('amount') || 0)
        const period = parseInt(params.get('period') || 0)
        console.log(amount, period)
        APIConfig.get(`get_user_offers_random?offer_type=${offerType}&period=${period}&amount=${amount}`).then(res => {
            const randomOffers = res.data.offers_list.sort(function (a, b) {
                return 0.5 - Math.random()
            })
            setOffersList(randomOffers.slice(0, limit))
            APIConfig.post('update_impressions', handleOfferImpressions(randomOffers, offers_list_id)).then(status => {
                setShowPreloader(false)
            })
        })
    }
    useEffect(() => {
        const OFFERS_LIST_ID = uuidv4()
        localStorage.setItem('offers_list_id', OFFERS_LIST_ID)
        if (localStorage.getItem('user_id') === null) {
            localStorage.setItem('user_id', uuidv4())
            localStorage.setItem('inited_from_offers_list', 'true')
        }
        handleLinkParams()

        setTimeout(() => {
            getUserOffers(OFFERS_LIST_ID)
        }, 1000)

    }, [])
    return (
        <>
            {showPreloader ?
                <section className='position-relative overflow-hidden pt-5 py-5 pt-lg-3 preloader__section'>
                    <img src={require('../assets/images/Rolling-1s-128px.gif')} width={48}/>
                    <p className='mt-3'>Подбираем персональные предложения...</p>
                </section>
                : <>
                    <PageHeader currentStep={4}/>
                    <section className='position-relative overflow-hidden pt-5 pt-lg-3 form-section__wrapper'>
                        <div className='container'>
                            <div className='row mt-2 mb-5'>
                                <div className="col-lg-8 mx-auto text-center">
                                    <h2 className="fs-1">Персональные предложения 🔥</h2>
                                    <p className="mb-0">
                                        Для гарантированного получения займа, нужно оставить заявку минимум в 3 МФО
                                    </p>
                                </div>
                            </div>

                            {cardType < 0.5 ?
                                <div className='row'>
                                    {offersList.map((offer, position) => {
                                        return (
                                            <div className='col-md-3 col-sm-12 mb-3 mb-md-0' key={offer.id}>
                                                <AdvCardItem
                                                    imgAlt={offer.name}
                                                    imgSource={offer.image_url}
                                                    imgTitle={offer.name}
                                                    badgeText={offer.badge.text}
                                                    badgeClass={offer.badge.class}
                                                    offerLink={`/go/${offer.id}`}
                                                    loanAmount={`до ${offer.amount_to.toLocaleString()} руб.`}
                                                    cardText={`от ${offer.rate}% в день`}
                                                    webmasterId={webmasterId}
                                                    offerPosition={position + 1}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                <div className='row'>
                                    {offersList.map((offer, position) => {
                                        return (
                                            <div className='col-md-3 col-sm-12 mb-3 mb-md-0' key={offer.id}>
                                                <RegularCardItem
                                                    imgAlt={offer.name}
                                                    imgSource={offer.image_url}
                                                    imgTitle={offer.name}
                                                    badgeText={offer.badge.text}
                                                    badgeClass={offer.badge.class}
                                                    offerLink={`/go/${offer.id}`}
                                                    loanAmount={`до ${offer.amount_to.toLocaleString()} руб.`}
                                                    cardText={`от ${offer.rate}% в день`}
                                                    webmasterId={webmasterId}
                                                    offerPosition={position + 1}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            }


                        </div>
                    </section>
                    <PageFooter currentStep={4}/>
                </>
            }
        </>
    )
}

export default OffersListSectionRandomBackend